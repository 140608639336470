exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-club-activities-js": () => import("./../../../src/pages/club_activities.js" /* webpackChunkName: "component---src-pages-club-activities-js" */),
  "component---src-pages-companion-js": () => import("./../../../src/pages/companion.js" /* webpackChunkName: "component---src-pages-companion-js" */),
  "component---src-pages-contest-js": () => import("./../../../src/pages/contest.js" /* webpackChunkName: "component---src-pages-contest-js" */),
  "component---src-pages-course-js": () => import("./../../../src/pages/course.js" /* webpackChunkName: "component---src-pages-course-js" */),
  "component---src-pages-gallery-list-js": () => import("./../../../src/pages/gallery_list.js" /* webpackChunkName: "component---src-pages-gallery-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-swift-res-js": () => import("./../../../src/pages/swift_res.js" /* webpackChunkName: "component---src-pages-swift-res-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-member-page-js": () => import("./../../../src/templates/member_page.js" /* webpackChunkName: "component---src-templates-member-page-js" */)
}

